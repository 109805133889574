// need for orders page functions
window.forgotPassword = forgotPassword;

$(document).ready(function(){
  $('.header-search-btn').click(function(e){
    $('.search_window').toggle();
    $('.header-search-btn').removeClass('gray');
    if($(".search_window").is(':visible')){
      $('.header-search-btn').addClass('gray');
    }else{
      $('.header-search-btn').removeClass('gray');
    }
  });
});

document.addEventListener('DOMContentLoaded', function () {
  let tagsBlock = document.querySelector('.products__tags');
  let btn = document.querySelector('.tags__btn-open');
  let path = document.querySelector('.tags__btn-path');

  if (btn) {
    btn.onclick = function (event) {
      if (event.currentTarget === btn) {
        if (path.parentElement.style.transform === 'rotate(-90deg)') {
          path.parentElement.style.transform = 'rotate(0deg)';
          tagsBlock.style.height = 'auto';
        } else {
          path.parentElement.style.transform = 'rotate(-90deg)';
          tagsBlock.style.height = '46px';
        }
      }
    };
  }

  let cards = document.querySelectorAll('.product-card-popup');
  let height = '';

  for (let card of cards) {
    height = card.parentElement.style.height;
    card.parentElement.onmouseover = function (event) {
      card.style.display = 'block';
      card.parentElement.style.boxShadow = '0px 0px 40px 2px rgba(0, 0, 0, 0.34)';
      card.parentElement.style.position = 'absolute';
      card.parentElement.style.height = 'auto';
      card.parentElement.style.zIndex = '14';
    };
    card.parentElement.onmouseout = function (event) {
      card.parentElement.style.zIndex = '0';
      card.style.display = 'none';
      card.parentElement.style.position = 'relative';
      card.parentElement.style.boxShadow = '0px 0px 6px 2px rgba(0, 0, 0, 0.34)';
      card.parentElement.style.height = `${height}`;
    };
  }

  addCallbackClick();

  addSendFormRequestClick();

  forgotPasswordOnLogin();

  authNotification();

  setTimeout(requestMetrika, 300);
});

/**
 * Get by ajax request from Orders controller Yii captcha html element and parse.
 * Render it in forget form. Add events for refresh.
 * Problem with images synchronization if more than one captcha on page.
 * TODO Delete after testing
 * @deprecated Use renderCaptchaInForgetFormWithRefreshing() instead
 */
function renderCaptchaInForgetFormByParsingHTML() {
  const captchaBlockEl = document.querySelector('.js_forgetCaptcha');

  // jQuery can't work with blob, need to parse page with captcha
  const captchaUrl = '/get-captcha';

  // Hack for refreshing Yii captcha. Use image path with get param:
  // const refreshCaptchaUrl = `${imgSrc}?refresh`;
  // or use with controller name (/{controllerName}/{captchaActionName}/refresh):
  const refreshCaptchaUrl = `/orders/captcha/refresh`;

  $.ajax({
    url: captchaUrl,
    success: function(data) {
      const domParser = new DOMParser();
      const imgSrc = domParser.parseFromString(data, "text/html").querySelector('img').src;

      const imageEl = document.createElement('img');
      imageEl.src = imgSrc;
      captchaBlockEl.append(imageEl);

      const spanEl = document.createElement('span');
      spanEl.textContent = 'Обновить';
      captchaBlockEl.append(spanEl);

      captchaBlockEl.addEventListener('click', () => refreshCaptchaInBlock(refreshCaptchaUrl, imageEl));
    }
  });
}

/**
 * Get captcha by refreshing url. Parse json and render it in forget form.
 * Add events for refresh.
 * Use '/orders/captcha-forget-form'
 */
function renderCaptchaInForgetFormWithRefreshing() {
  const captchaBlockEl = document.querySelector('.js_forgetCaptcha');

  const captchaRefresh = '/orders/captcha-forget-form/refresh';

  $.ajax({
    url: captchaRefresh,
    dataType: 'json',
    success: function(data) {
      const imgSrc = data.url;

      const imageEl = document.createElement('img');
      imageEl.src = imgSrc;
      captchaBlockEl.append(imageEl);

      const spanEl = document.createElement('span');
      spanEl.textContent = 'Обновить';
      captchaBlockEl.append(spanEl);

      captchaBlockEl.addEventListener('click', () => refreshCaptchaInBlock(captchaRefresh, imageEl));
    }
  });
}

function refreshCaptchaInBlock(refreshCaptchaUrl, imageEl) {
  $.ajax({
    url: refreshCaptchaUrl,
    dataType: 'json',
    success: function(data) {
      imageEl.src = data.url;
    }
  });
}

function addCallbackClick() {
  document.querySelector('a.feedback-btn.free_call_wrapper_one').addEventListener('click', () => {
    let target = document.querySelector('a[data-b24-crm-button-widget=callback]');

    if (target) {
      target.click();
    }
  });
}

function addSendFormRequestClick() {
  document.querySelectorAll('.js_sendRequestForm').forEach((el) =>
    el.addEventListener('click', (e) => {
      let target = document.querySelector('a[data-b24-crm-button-widget=crmform]');

      if (target) {
        target.click();
      }
    })
  );
}

function forgotPasswordOnLogin() {
  let button = document.querySelector('.modal.fade#login a.forgot-pass');

  if (button) {
    forgotPassword(button);
  }
}

function forgotPassword(button = false) {
  if (!button) button = document.querySelector('[data-forgot-pass]');

  if (button) {
    const captchaImageBlock = document.querySelector('.js_forgetCaptcha');

    button.addEventListener('click', function (e) {
      e.preventDefault();

      if (!captchaImageBlock.childElementCount) {
        // Render captcha if not rendered yet.
        renderCaptchaInForgetFormWithRefreshing();
      }

      let parent = $(button).closest('.modal.fade');

      if (parent) {
        parent.modal('hide');
      }

      let modal = document.querySelector('.modal#forgot-password');

      if (modal) {
        modal.style.display = 'block';

        let close = modal.querySelector('button.close');

        close.addEventListener('click', function () {
          modal.style.display = 'none';
        });

        let submitButton = modal.querySelector('input[name=submit]');

        if (submitButton) {
          submitButton.addEventListener('click', function (ev) {
            let phone = modal.querySelector('input[name=phoneforgot]').value;
            let verifyCode = modal.querySelector('input[name=verifyCode]').value;

            $.ajax({
              url: '/forgotpassword',
              type: 'post',
              data: {
                phone: phone,
                verify: verifyCode,
              },
              success: function (data) {
                data = JSON.parse(data);

                let messageBlock = modal.querySelector('p.message');

                if (messageBlock) {
                  switch (data['status']) {
                    case 'ok':
                      messageBlock.classList.add('success');
                      messageBlock.classList.remove('error');
                      break;
                    case 'error':
                      messageBlock.classList.add('error');
                      messageBlock.classList.remove('success');
                      break;
                  }

                  messageBlock.textContent = data['message'];
                }
              },
            });
          });
        }
      }
    });
  }
}

function authNotification() {
  let modal = document.querySelector('.modal#auth-ok');

  if (modal) {
    modal.style.display = 'block';

    let close = modal.querySelector('button.close');

    close.addEventListener('click', function () {
      modal.style.display = 'none';
    });
  }
}

/**
 * Render yandex metrika script. If google recaptcha is enabled, render only for non bot users
 */
function requestMetrika() {
  if (typeof grecaptcha !== 'undefined') {
    grecaptcha.ready(function () {
      let recaptchaPublic = document.querySelector('meta[name=recaptcha_public_key]');
      if (recaptchaPublic) {
        let recaptchaPublicKey = recaptchaPublic.content;
        if (recaptchaPublicKey) {
          grecaptcha.execute(recaptchaPublicKey, { action: 'CHECK' }).then(function (token) {
            $.ajax({
              url: '/get-metrika',
              type: 'post',
              data: {
                token: token,
              },
              success: function (data) {
                data = JSON.parse(data);
                if (data['status'] === 'ok' && data['access'] === 'allow' && data['metrikaID']) {
                  metrika(data['metrikaID']);
                }
              },
            });
          });
        }
      }
    });
  } else {
    console.log('Google reCaptcha disabled');
    $.ajax({
      url: '/get-metrika',
      type: 'post',
      data: {
        token: '',
      },
      success: function (data) {
        data = JSON.parse(data);
        if (data['status'] === 'ok' && data['access'] === 'allow' && data['metrikaID']) {
          metrika(data['metrikaID']);
        }
      },
    });
  }

    function metrika(id)
    {
        (function (m, e, t, r, i, k, a) {
            m[i] = m[i] || function () {
                (m[i].a = m[i].a || []).push(arguments)
            };
            m[i].l = 1 * new Date();
            for (var j = 0; j < document.scripts.length; j++) {
                if (document.scripts[j].src === r) {
                    return;
                }
            }
            k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
        })
        (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

        ym(id, "init", {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true
        });
    }

}
