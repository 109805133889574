document.addEventListener('DOMContentLoaded', () => {
  new DropdownFilter().init();
});

/**
 * Temporary class to working with filter's legacy code. Use jQuery.
 * Open mobile's filter by any part of it, not only by button
 * Need refactoring after removing js code from php files.
 */
class DropdownFilter {
  constructor() {
    this._filterSelector = '.varian_filter';
    this._filters = null;

    this._filterOpenButtonSelector = '.button_filters';
  }

  init() {
    if (this._initElements()) {
      this._initEvents();
    }
  }

  /**
   * Initialize html elements for this class.
   * @returns {boolean} Return false if elements for working with not found
   */
  _initElements() {
    this._filters = document.querySelectorAll(this._filterSelector);

    return this._filters.length !== 0;
  }

  _initEvents() {
    this._filters.forEach((el) => el.addEventListener('click', this._clickHandler.bind(this)));
  }

  _clickHandler(event) {
    const button = event.currentTarget.querySelector(this._filterOpenButtonSelector);

    if (button !== null) {
      // Use jQuery to prevent double event. Not needed after migrating from jQuery .on() function
      $(button).trigger('click');
    }
  }
}
